document.addEventListener('DOMContentLoaded', () => {
    const items = document.querySelectorAll('.selector-item');
    const selectors = document.querySelectorAll('.selector');
    let currentIndex = 0;

    const setActiveSelector = (index) => {
        const selectorContainer = document.getElementsByClassName("selectors")
        selectorContainer[0]?.classList?.toggle('closed')
        items.forEach((item, idx) => {
            item.classList?.toggle('active', idx === index);
            selectors[idx]?.classList.toggle('active', idx === index);
        });
    };

    const switchSelectorSlide = (direction) => {
        items[currentIndex].classList.remove('active');
        selectors[currentIndex].classList.remove('active');

        if (direction === 'next') {
            currentIndex = (currentIndex + 1) % items.length;
        } else if (direction === 'prev') {
            currentIndex = (currentIndex - 1 + items.length) % items.length;
        }

        setActiveSelector(currentIndex);
    };

    // Initial setup
    setActiveSelector(0);

    document.querySelectorAll('.selector').forEach((selector, index) => {
        selector.addEventListener('click', () => {
            currentIndex = index;
            setActiveSelector(index);
        });
    });

    document.querySelector('.prev-arrow')?.addEventListener('click', () => switchSelectorSlide('prev'));
    document.querySelector('.next-arrow')?.addEventListener('click', () => switchSelectorSlide('next'));
});
